<template>
  <div class="carouselWrap">
    <Carousel :bannerList="bannerLists" />
  </div>
  <div class="mainwidth">
    <!-- table -->
    <div class="content">
      <div class="policyList">
        <div v-for="(item, index) in policyList" :key="index">
          <div
            style="display: flex;justify-content: space-between;align-items: center;"
          >
            <div class="title" @click="handleClickGo(item)">
              {{ item.title }}
            </div>
            <div
              class="declare"
              v-if="item.review_status == '1'"
              @click="handleClickGo(item)"
            >
              立即申报
            </div>
            <div class="declare" v-if="item.review_status == '0'">申请完成</div>
            <div class="declare" v-if="item.review_status == '2'">申请中</div>
          </div>
          <div style="display: flex;">
            <a-tag color="#87d068" style="width: 10%;text-align: center">{{
              item.tag
            }}</a-tag>
            <div
              style="display: flex;justify-content: space-between;margin-left: 20px;width: 90%;"
            >
              <div>发布时间:{{ item.start_time }}</div>
              <div>截止时间:{{ item.end_time }}</div>
            </div>
          </div>
          <a-divider></a-divider>
        </div>
      </div>
      <!-- 记录 -->
      <div class="record">
        <p>申报记录</p>
        <div class="box" v-if="isLogin">
          <div
            class="record-item"
            v-for="(item, index) in recordList"
            :key="index"
          >
            <div style="padding-right: 20px;">{{ item.title }}</div>
            <a-divider></a-divider>
          </div>
        </div>
        <div class="no-login" v-if="!isLogin">
          <div>点击登录,查看申报详情</div>
          <a-button type="primary" @click="handleClickToLogin"
            >确认登录</a-button
          >
        </div>
      </div>
    </div>
    <div class="page-box">
      <a-pagination
        show-quick-jumper
        v-model:current="page"
        :total="total"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import { getPolicyList, declarationList } from "../api/financialPrefecture";
import Carousel from "../components/Carousel";
import { mapGetters } from "vuex";
export default {
  name: "PolicyList",
  components: {
    Carousel,
  },

  computed: {
    ...mapGetters([
      "orgId",
      "isAuth",
      "loginType",
      "orgName",
      "isLogin",
      "userId",
    ]),
  },
  data() {
    return {
      bannerLists: [
        require("../assets/images/PolicyList-1.jpg"),
        require("../assets/images/PolicyList-2.jpg"),
      ],
      policyList: [
        // {
        //   title: "政策标题标题政策标题标题",
        // },
        // {
        //   title: "政策标题标题政策标题标题",
        // },
        // {
        //   title: "政策标题标题政策标题标题",
        // },
        // {
        //   title: "政策标题标题政策标题标题",
        // },
        // {
        //   title: "政策标题标题政策标题标题",
        // },
      ],
      recordList: [
        // {
        //   text:
        //     "《政策标题政策标题政策标题政策标题题政策标题题政策标题题政策标题政策标题政策标题》",
        // },
        // {
        //   text:
        //     "《政策标题政策标题政策标题政策标题题政策标题题政策标题题政策标题政策标题政策标题》",
        // },
        // {
        //   text:
        //     "《政策标题政策标题政策标题政策标题题政策标题题政策标题题政策标题政策标题政策标题》",
        // },
        // {
        //   text:
        //     "《政策标题政策标题政策标题政策标题题政策标题题政策标题题政策标题政策标题政策标题》",
        // },
        // {
        //   text:
        //     "《政策标题政策标题政策标题政策标题题政策标题题政策标题题政策标题政策标题政策标题》",
        // },
        // {
        //   text:
        //     "《政策标题政策标题政策标题政策标题题政策标题题政策标题题政策标题政策标题政策标题》",
        // },
        // {
        //   text:
        //     "《政策标题政策标题政策标题政策标题题政策标题题政策标题题政策标题政策标题政策标题》",
        // },
        // {
        //   text:
        //     "《政策标题政策标题政策标题政策标题题政策标题题政策标题题政策标题政策标题政策标题》",
        // },
        // {
        //   text:
        //     "《政策标题政策标题政策标题政策标题题政策标题题政策标题题政策标题政策标题政策标题》",
        // },
      ],
      page: 1,
      total: null,
    };
  },
  methods: {
    onChange(pageNumber) {
      this.page = pageNumber;
      this.getList();
    },
    // 跳转页面
    handleClickGo(item) {
      this.$router.push({
        path: "/PolicyDetails",
        query: {
          id: item.id,
          status: item.review_status,
        },
      });
    },
    getList() {
      getPolicyList({
        type: 3,
        user_id: this.userId ? this.userId : "null",
        currentPage: this.page,
        showCount: 5,
      }).then((res) => {
        this.policyList = res.varList;
        this.total = res.varList.length;
      });
    },
    // 政策申报记录
    getDeclarationList() {
      declarationList().then((res) => {
        console.log(res);
        this.recordList = res.varList;
      });
    },
    // 未登录显示的登录按钮事件
    handleClickToLogin() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.getList();
    this.getDeclarationList();
  },
};
</script>

<style lang="less" scoped>
.carouselWrap {
  width: 1440px;
  margin: 0 auto;
}
.mainwidth {
  padding-top: 20px;
  padding-bottom: 20px;
  .content {
    display: flex;
    justify-content: space-between;
    .policyList {
      margin-top: 20px;
      width: 59%;
      height: 400px;
      .title {
        font-weight: bold;
        font-size: 18px;
      }
      .declare {
        border: 1px solid #ccc;
        padding: 4px;
        font-size: 12px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    .record {
      width: 39%;
      margin-top: 20px;
      border-left: 1px solid #ccc;
      padding: 10px 20px;
      height: 400px;

      p {
        font-weight: bold;
        font-size: 18px;
      }
      .box {
        height: 340px;
        overflow-y: auto;
      }
      .record-item {
        font-size: 14px;
      }
    }
  }
}
.page-box {
  padding: 15px 20px;
  background-color: @color-ff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #929292;
  margin-bottom: 30px;
  position: relative;
  margin-top: 20px;
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
}
.page-box::before {
  content: "";
  position: absolute;
  left: -3px;
  top: 11px;
  width: 6px;
  height: 40px;
  background: @color-blue;
}
.page-box::after {
  content: "";
  position: absolute;
  right: -3px;
  top: 11px;
  width: 6px;
  height: 40px;
  background: @color-blue;
}
.ant-divider-horizontal {
  margin: 12px 0;
}
.no-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  div {
    font-size: 18px;
    font-weight: 100;
    margin-bottom: 20px;
  }
}
</style>
